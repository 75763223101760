import * as React from 'react'
import PropTypes from 'prop-types'

import { lazy as loadable } from '@loadable/component'

import * as ServerConfig from '../server-configuration'
import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import { UniversalFetch } from '../universal-fetch'
import { createLocalizedComponent } from '../create-localized-component'

const LOCALIZED_COMPONENTS = {
  __default__: loadable(() => import('./landing-page-default')),
  __unsupported__: loadable(() => import('./landing-page-unsupported')),
  FI: loadable(() => import('./landing-page-finland')),
  CA: loadable(() => import('./landing-page-canada')),
  NL: loadable(() => import('./landing-page-canada')),
  NZ: loadable(() => import('./landing-page-new-zeland')),
}

const LandingPage = createLocalizedComponent(
  'LandingPage',
  LOCALIZED_COMPONENTS
)

const LoginPage = loadable(() => import('../login-page'))

export function LandingPageContainer(props) {
  const [returningPlayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )
  const { country } = ServerConfig.useContext()

  if (returningPlayer && country.alpha2 !== 'FI') {
    return <LoginPage />
  }

  return (
    <UniversalFetch onFetch={props.onFetch}>
      <LandingPage {...props} returningPlayer={returningPlayer} />
    </UniversalFetch>
  )
}

LandingPageContainer.propTypes = {
  onFetch: PropTypes.func.isRequired,
}
